const predefinedPlaceholders = {
  Seller: {
    "מוכר 1": [
      "סוג_זיהוי_מוכר_1",
      "תז_מוכר_1",
      "שם_משפחה_מוכר_1",
      "שם_פרטי_מוכר_1",
      "כתובת_יישוב_מוכר_1",
      "כתובת_רחוב_מוכר_1",
      "כתובת_מספר_מוכר_1",
      "החלקים_בזכות_מוכר_1",
      "החלק_המועבר_מוכר_1",
    ],
    "מוכר 2": [
      "סוג_זיהוי_מוכר_2",
      "תז_מוכר_2",
      "שם_פרטי_מוכר_2",
      "שם_משפחה_מוכר_2",
      "כתובת_יישוב_מוכר_2",
      "כתובת_רחוב_מוכר_2",
      "כתובת_מספר_מוכר_2",
      "החלקים_בזכות_מוכר_2",
      "החלק_המועבר_מוכר_2",
    ],
  },
  "Property data": {
    "פרטי הנכס": [
      "גוש", 
      "חלקה", 
      "תת_חלקה"
    ],
    "כתובת הנכס": [
      "מקרקעין_כתובת_יישוב", 
      "מקרקעין_כתובת_רחוב",
      "מקרקעין_כתובת_מספר",
      "קומה",
      "מספר_חדרים",
      "שטח_רשום",
      "מהות_הזכות_במקרקעין",
      "החלקים_בזכות",
      "החלק_המועבר",
    ],
  },
  Buyer: {
    "קונה 1": [
      "סוג_זיהוי_קונה_1",
      "תז_קונה_1",
      "שם_פרטי_קונה_1",
      "שם_משפחה_קונה_1",
      "כתובת_יישוב_קונה_1",
      "כתובת_רחוב_קונה_1",
      "כתובת_מספר_קונה_1",
      "החלקים_בזכות_קונה_1",
      "החלק_המועבר_קונה_1",
    ],

    "קונה 2": [
      "סוג_זיהוי_קונה_2",
      "תז_קונה_2",
      "שם_פרטי_קונה_2",
      "שם_משפחה_קונה_2",
      "כתובת_יישוב_קונה_2",
      "כתובת_רחוב_קונה_2",
      "כתובת_מספר_קונה_2",
      "החלקים_בזכות_קונה_2",
      "החלק_המועבר_קונה_2",
    ],
  },
  DealInfo: {
    "כללי": [
      "תמורה",
      "תשלום_ראשון",
      "תשלום_שני",
      "תשלום_שלישי",
      "מועד_תשלום_שני",
      "מועד_תשלום_שלישי",
      "יתרת_התמורה",
      "מועד_המסירה",
    ]
  },
  Miscellaneous: {},
  // Add more types and their predefined categories here
};
  
  export default predefinedPlaceholders;
  