// theme.js
import { createTheme } from "@mui/material/styles";

// Define custom transition durations and easing functions
const customTransitions = {
  duration: {
    shortest: 150,
    shorter: 200,
    short: 250,
    standard: 300,
    complex: 375,
    enteringScreen: 225,
    leavingScreen: 195,
  },
  easing: {
    easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
    easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
    easeIn: "cubic-bezier(0.4, 0, 1, 1)",
    sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
  },
};

const theme = createTheme({
  direction: 'rtl',
  
  palette: {
    primary: {
      main: "#556cd6", // Replace with your primary color
    },
    secondary: {
      main: "#19857b", // Replace with your secondary color
    },
    action: {
      hover: "#188977", // Color when hovered
      selected: "#188977", // Color when selected
    },
    drawerText: {
      main: '#DEEDCF', // Replace with your desired color
    },
    // Add any additional colors you want to use
  },
  
  // Integrate custom transitions
  transitions: {
    ...customTransitions,
    // You can customize or override specific transitions if needed
    // For example:
    // duration: {
    //   ...customTransitions.duration,
    //   // Add or override specific durations
    // },
    // easing: {
    //   ...customTransitions.easing,
    //   // Add or override specific easing functions
    // },
  },

  // Optional: Customize typography, breakpoints, etc.
  typography: {
    // Example: Customize font family
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  
  // Optional: Customize other theme properties
  // For example, spacing, shape, etc.
});

export default theme;
