import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

import FormSelector from "./FormSelector";
import GenericForm from "./GenericForm";
import { useForm } from "./FormContext";
import { useContracts } from '../Docx/ContractsContext';
import ErrorAlert from "../UI/ErrorAlert";

const FormContainer = () => {
  const location = useLocation();
  const { updateFormData, formData, selectedFiles } = useForm();
  const { contracts, govContracts } = useContracts();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  useEffect(() => {
    const editJsonMode = location.state ? location.state.deal.json : null;
    buildDynamicFormData(editJsonMode);
  }, [selectedFiles]);

  const buildDynamicFormData = async (editJsonMode) => {
    try {
      let response;
      if (!editJsonMode) {
        const filteredContracts = contracts.filter((contract) =>
          selectedFiles.includes(contract.name)
        );
        const filteredGovContracts = govContracts.filter((contract) =>
          selectedFiles.includes(contract.name)
        );
        const combinedContracts = [
          ...filteredContracts,
          ...filteredGovContracts,
        ];

        const responseForm = buildCompleteJsonStructure(combinedContracts);
        response = responseForm;
      } else {
        response = editJsonMode;
      }
      console.log("buildDynamicFormData successful:", response);
      updateFormData(response);
    } catch (error) {
      console.error("buildDynamicFormData failed:", error);
      setErrorDialogOpen(true);
    }
  };

  const initialJsonStructure = [
    {
      type: "Seller",
      title: "מוכר",
      allowMultiple: true,
      inputs: [],
      inputsMap: {},
    },
    {
      type: "Property data",
      title: "פרטי הנכס",
      allowMultiple: false,
      inputs: [],
      inputsMap: {},
    },
    {
      type: "Buyer",
      title: "קונה",
      allowMultiple: true,
      inputs: [],
      inputsMap: {},
    },
    {
      type: "DealInfo",
      title: "פרטי העיסקה",
      allowMultiple: true,
      inputs: [],
      inputsMap: {},
    },
    {
      type: "Miscellaneous",
      title: "שונות",
      allowMultiple: false,
      inputs: [],
      inputsMap: {},
    },
  ];

  const mapDictionaryToJSON = (dictionary, jsonStructure) => {
    return jsonStructure.map((category) => {
      const categoryType = category.type;
      const inputsMap = category.inputsMap || {};

      Object.keys(dictionary.payload).forEach((key) => {
        if (categorizeKey(dictionary.payload[key]) === categoryType) {
          const label = dictionary.payload[key].replace(/_/g, " ");
          if (!inputsMap[label]) {
            inputsMap[label] = {
              type: "TextInput",
              label: label,
              placeholder: dictionary.payload[key],
            };
          }
        }
      });

      return {
        ...category,
        inputsMap: inputsMap,
        inputs: Object.values(inputsMap),
      };
    });
  };

  // Sets for O(1) lookup
  const buyerKeywords = new Set([
    "סוג_זיהוי_קונה",
    "תז_קונה",
    "שם_פרטי_קונה",
    "שם_משפחה_קונה",
    "כתובת_יישוב_קונה",
    "כתובת_רחוב_קונה",
    "כתובת_מספר_קונה",
    "החלקים_בזכות_קונה",
    "החלק_המועבר_קונה",
  ]);
  const sellerKeywords = new Set([
    "סוג_זיהוי_מוכר",
    "תז_מוכר",
    "שם_פרטי_מוכר",
    "שם_משפחה_מוכר",
    "כתובת_יישוב_מוכר",
    "כתובת_רחוב_מוכר",
    "כתובת_מספר_מוכר",
    "החלקים_בזכות_מוכר",
    "החלק_המועבר_מוכר",
  ]);
  const propertyDataKeywords = new Set([
    "גוש",
    "חלקה",
    "תת_חלקה",
    "מקרקעין_כתובת_יישוב",
    "מקרקעין_כתובת_רחוב",
    "מקרקעין_כתובת_מספר",
    "קומה",
    "מספר_חדרים",
    "שטח_רשום",
    "מהות_הזכות_במקרקעין",
    "החלקים_בזכות",
    "החלק_המועבר",
  ]);
  const dealInfo = new Set([
    "תמורה",
    "תשלום_ראשון",
    "תשלום_שני",
    "תשלום_שלישי",
    "מועד_תשלום_שני",
    "מועד_תשלום_שלישי",
    "יתרת_התמורה",
    "מועד_המסירה",
  ]);

  const isSomething = (key, keywords) => {
    for (const someKeyword of keywords) {
      if (key.includes(someKeyword)) {
        return true;
      }
    }
    return false;
  }

  const isBuyer = (key) => {
    return isSomething(key, buyerKeywords)
  };

  const isSeller = (key) => {
    return isSomething(key, sellerKeywords)
  };

  const isPropertyData = (key) => {
    console.log(key)
    return isSomething(key, propertyDataKeywords)
  };

  const isDealInfo = (key) => {
    return isSomething(key, dealInfo)
  };

  const categorizeKey = (key) => {
    if (isBuyer(key)) {
      return "Buyer";
    } else if (isSeller(key)) {
      return "Seller";
    } else if (isPropertyData(key)) {
      return "Property data";
    } else if (isDealInfo(key)) {
      return "DealInfo"
    } else {
      return "Miscellaneous";
    }
  };

  const buildCompleteJsonStructure = (dictionaries) => {
    return dictionaries.reduce((acc, dictionary) => {
      return mapDictionaryToJSON(dictionary, acc);
    }, initialJsonStructure);
  };

  const onClose = () => {
    setErrorDialogOpen(false);
  };

  const onRetryFetchData = async () => {
    setErrorDialogOpen(false);
    await buildDynamicFormData(null);
  };

  if (!formData) return <div>Loading...</div>;

  return (
    <div>
      <ErrorAlert
        open={errorDialogOpen}
        onClose={onClose}
        onRetry={onRetryFetchData}
      />
      <FormSelector />
      <GenericForm />
    </div>
  );
};

export default FormContainer;
